<template>
  <a-card :bordered="false" class="card-area">

    <a-descriptions title="经销商产品管理" bordered size="small" :column="{sm:2}">
      <a-descriptions-item label="经销商">{{vendor.name}}</a-descriptions-item>
      <a-descriptions-item label="账号">{{vendor.account}}</a-descriptions-item>
      <a-descriptions-item label="备注">{{vendor.remark}}</a-descriptions-item>
      <a-descriptions-item label="创建时间">{{vendor.createdAt}}</a-descriptions-item>

    </a-descriptions>

    <common-table
      ref="table"
      path="product/page"
      :query-params="queryParams"
      :columns="columns">

      <template slot="search">
        <a-button type="primary" ghost @click="add" >产品授权</a-button>
      </template>

      <template slot="operation" slot-scope="{record}">
        <action-view @click="view(record)"></action-view>
        <action-delete @click="remove(record)"></action-delete>
      </template>

    </common-table>

    <vendor-product-add ref="vendorProductAdd" @success="getList"></vendor-product-add>

  </a-card>

</template>
<script>

  import VendorProductAdd from "./VendorProductAdd";
  export default {
    components:{VendorProductAdd},
    data () {
      return {
        queryParams:{
          vendorId:0
        },
        vendor:{},
      }
    },
    created () {

      this.queryParams.vendorId = this.$route.params.vendorId

      this.$get('vendor/'+this.queryParams.vendorId).then((r) => {
        let data = r.data
        this.vendor = data
      })

    },
    computed: {
      columns () {
        return [
          {
            title: '产品',
            dataIndex: 'name',
          },
          {
            title: 'ProductKey',
            dataIndex: 'productKey'
          },
          {
            title: '授权设备',
            dataIndex: 'allDevice',
            customRender(text){
              return text?"全部设备":"部分设备"
            }
          },
          {
            title: '设备数量',
            dataIndex: 'deviceCount'
          },
          {
            title: '操作',
            dataIndex: 'operation',
            scopedSlots: {customRender: 'operation'},
          }
        ]
      },

    },
    methods: {

      add(){
        this.$refs.vendorProductAdd.show(this.queryParams.vendorId)
      },

      view (record) {
        let vendorId = this.queryParams.vendorId
        this.$router.push(`/vendor/${vendorId}/device?productKey=${record.productKey}`)
      },
      remove(record){
        var that = this
        this.$confirm({
          title: '是否删除该产品授权?',
          content: '确定后将会同时删除该产品下的设备授权',
          centered: true,
          onOk () {
            that.$delete('vendor/' + that.$route.params.vendorId + '/product/' + record.productKey).then(() => {
              that.$message.success('删除成功')
              that.getList()
            })
          },

        })
      },
      getList () {
        this.$refs.table.getData()
      }
    }
  }
</script>
<style lang="less" scoped>

</style>
